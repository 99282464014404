import GoogleAnalyticsUtils from 'chairisher/util/googleanalytics';

import { AmplitudeEventProperty, logAmplitudeEvent, TrackingScreen } from 'chairisher/util/analytics';

/**
 * Get amplitude event properties common to amplitude events which log interactions with sellers (e.g.,
 * email, call, contact).
 *
 * @param {string} screen The screen this analytics call will be performed on
 * @param {string} sellerGuid Guid of the seller being contacted
 * @param {ProductModel=} optProduct If present, the product being inquired about
 * @returns {Object} Map of event properties to values
 */
function getBaseSellerEventProperties(screen, sellerGuid, optProduct) {
    const amplitudeEventProperties = {
        [AmplitudeEventProperty.SCREEN]: screen,
        [AmplitudeEventProperty.TO_GUID]: sellerGuid,
    };

    if (optProduct) {
        amplitudeEventProperties[AmplitudeEventProperty.PRODUCT_ID] = optProduct.getId();
        amplitudeEventProperties[AmplitudeEventProperty.PRODUCT_PRICE] = optProduct.getPrice();
        amplitudeEventProperties[AmplitudeEventProperty.PRODUCT_TAXONOMY] = optProduct.getTaxonomy();
    }

    return amplitudeEventProperties;
}

/**
 * Tracks attempt to display dealer contact info
 *
 * @param {jQuery.Event} event The jQuery event object triggering this analytics call
 * @param {string} screen The screen this analytics call is being performed on
 * @param {string} dealerGuid The guid of the dealer
 * @param {ProductModel=} optProduct If present, the product the dealer is being contacted about
 * @param {string=} ctaPosition Optional position to which to attribute the contact
 */
export function trackContactDealer(event, screen, dealerGuid, optProduct, ctaPosition = null) {
    const eventProperties = getBaseSellerEventProperties(screen, dealerGuid, optProduct);
    if (ctaPosition) {
        eventProperties[AmplitudeEventProperty.CTA_POSITION] = ctaPosition;
    }

    logAmplitudeEvent('designer - show contact modal', eventProperties);
    GoogleAnalyticsUtils.logEvent('designer - show contact modal', eventProperties);
}

/**
 * Tracks attempt to send email to sellers using our own form
 *
 * @param {jQuery.Event} event The jQuery event object triggering this analytics call
 * @param {string} screen The screen this analytics call is being performed on
 * @param {string} dealerGuid The guid of the dealer
 * @param {ProductModel=} product If present, the product the dealer is being contacted about
 */
export function trackEmailDealerSend(event, screen, dealerGuid, product = null) {
    const eventProperties = getBaseSellerEventProperties(screen, dealerGuid, product);

    logAmplitudeEvent('designer - email send', eventProperties);
    GoogleAnalyticsUtils.logEvent('designer - email send', eventProperties);
}

/**
 * Tracks a click on a social media link.
 *
 * @param {jQuery.Event} event
 * @param {string} platform Name of the social media platfom
 * @param {string} screen
 * @param {string} dealerGuid Guid of the dealer
 */
export function trackVisitsDealerSocialMedia(event, platform, screen, dealerGuid) {
    const eventProperties = getBaseSellerEventProperties(screen, dealerGuid);

    logAmplitudeEvent(`designer - ${platform} click`, eventProperties);
    GoogleAnalyticsUtils.logEvent(`designer - ${platform} click`, eventProperties);
}

/**
 * Tracks the opening of a dealer's personal website
 *
 * @param {string} dealerGuid
 */
export function trackVisitsDealerWebsite(dealerGuid) {
    const eventProperties = { [AmplitudeEventProperty.TO_GUID]: dealerGuid };
    logAmplitudeEvent('designer - website click', eventProperties);
    GoogleAnalyticsUtils.logEvent('designer - website click', eventProperties);
}

/**
 * Track a request to place a product on hold.
 *
 * @param {ProductModel} product The product to place on hold.
 * @param {string} position
 */
export function trackHoldRequestCreate(product, position) {
    const amplitudeObject = {
        [AmplitudeEventProperty.PRODUCT_ID]: product.getId(),
        [AmplitudeEventProperty.PRODUCT_TAXONOMY]: product.getTaxonomy(),
        [AmplitudeEventProperty.IS_NEWLY_MADE]: product.getIsNewlyMade(),
        [AmplitudeEventProperty.POSITION]: position,
    };

    logAmplitudeEvent('hold - request create', amplitudeObject);
    GoogleAnalyticsUtils.logEvent('hold - request create', amplitudeObject);
}

/**
 * Tracks attempt to display hold request form
 *
 * @param {ProductModel} product The product to hold
 */
export function trackHoldRequestShow(product) {
    const eventProperties = getBaseSellerEventProperties(TrackingScreen.PDP, product.getDealerGuid(), product);

    logAmplitudeEvent('hold - request show', eventProperties);
    GoogleAnalyticsUtils.logEvent('hold - request show', eventProperties);
}
