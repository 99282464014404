import $ from 'jquery';

import { formatCurrency } from 'chairisher/util/format';
import {
    FacetTagNames,
    getDimensionFieldNames,
    getPriceFacetFieldNames,
    getSingularFieldNames,
} from 'chairisher/context/search';

/**
 * The selector for the container that summarizes facet selections
 */
const CONTAINER_SELECTOR = '.js-selected-facet-container';

/**
 * Displays a summary of selected facets and provides a way to deselect them outside of the actual facet
 */
class FacetSummaryView {
    constructor() {
        /**
         * Facet tags to skip when rendering clickable facet pills
         *
         * @type {Array.<string>}
         */
        this.facetTagsToSkip = [FacetTagNames.RADIUS];

        /**
         * Contains markup used to marshal a new facet tag (see: search/_facet_pill.html)
         *
         * @type {string}
         */
        this.facetTagTemplateMarkup = $('#js-template-facet-pill').html();

        /**
         * The selector of the form that contains the facet inputs being summarized
         *
         * @type {string}
         */
        this.refinementFormSelector = '#js-search-form';
    }

    /**
     * Adds a facet tag from the current search.
     *
     * @param {string} fieldName The name of the facet input being added
     * @param {string} fieldValue The value of the facet input being added
     * @param {boolean=} shouldUpdateTag True indicates a tag should be updated if it already exists
     */
    addFacetTag(fieldName, fieldValue, shouldUpdateTag = false) {
        if (!this.facetTagsToSkip.includes(fieldName)) {
            let wasUpdated = false;
            const $newTag = this.marshalFacetTag(fieldName, fieldValue);

            if (shouldUpdateTag) {
                const $tags = this.getFacetTags(fieldName);
                if ($tags.length) {
                    $tags.replaceWith($newTag);
                    wasUpdated = true;
                }
            }

            const $container = $(CONTAINER_SELECTOR);

            if (!wasUpdated) {
                $container.find('.js-btn-clear').before($newTag);
            }

            this.toggleFacetContainerVisibility(true);
        }
    }

    /**
     * Removes all facet tags from the container
     */
    clear() {
        // append facet tag elements to a DOM fragment before inserting into the DOM
        // to prevent unnecessary reflows and repaints...
        const $container = $(CONTAINER_SELECTOR);
        const $tempContainer = $container.clone();

        $tempContainer.find('.js-search-facet-pill').remove();
        $container.html($tempContainer.html());
        this.toggleFacetContainerVisibility(false);
    }

    /**
     * Displays a list of clickable facet tags for the element selected by this.refinementFormSelector
     *
     * @param {Array.<Object>} selections The list of facet tags to display with name and value keys for each
     */
    displayFacetTags(selections) {
        // append facet tag elements to a DOM fragment before inserting into the DOM
        // to prevent unnecessary reflows and repaints...
        const $container = $(CONTAINER_SELECTOR);
        const $tempContainer = $container.clone();

        $tempContainer.find('.js-search-facet-pill').remove();
        const $btn = $tempContainer.find('.js-btn-clear');

        selections.forEach((selection) => {
            if (!this.facetTagsToSkip.includes(selection.name) && selection.value) {
                $btn.before(this.marshalFacetTag(selection.name, selection.value));
            }
        });

        $container.html($tempContainer.html());
        this.toggleFacetContainerVisibility(selections.length > 0);
    }

    /**
     * @returns {string} The selector for the facet summary container
     */
    getContainerSelector() {
        return CONTAINER_SELECTOR;
    }

    /**
     * Finds the display string for an input that represents a search facet
     *
     * @param {string} fieldName The name of the facet input to get the display string for
     * @param {string} fieldValue The value of the facet input to get the display string for
     * @returns {string}
     */
    getFacetDisplayString(fieldName, fieldValue) {
        let displayString = fieldValue;
        const isPriceField = getPriceFacetFieldNames().includes(fieldName);
        const isDimensionField = getDimensionFieldNames().includes(fieldName);

        if (isPriceField || isDimensionField) {
            // This is a special case because users can set arbitrary values in the <input>s for min/max.
            // Note that below logic is duplicated in B/E code for rendering the price facet labels.
            let valueParts = fieldValue.split('-');
            if (isPriceField) {
                valueParts = valueParts.map((v) => (v ? formatCurrency(v) : null));
            }

            const [min, max] = valueParts;
            if (min && max) {
                displayString = `${min} - ${max}`;
            } else if (min) {
                displayString = `${min} and up`;
            } else if (max) {
                displayString = `under ${max}`;
            }

            if (isDimensionField) {
                displayString = `${fieldName}: ${displayString}`;
            }
        } else if (fieldName !== FacetTagNames.QUERY) {
            const $facetInput = this.getFacetInput(
                fieldName,
                this.isSingular(fieldName) ? undefined : fieldValue,
            ).parent();
            displayString = $facetInput.data('display-string');
            if ($facetInput.siblings('.js-facet-choices').length > 0) {
                displayString = `All ${displayString}`;
            }
        }
        return displayString;
    }

    /**
     * Returns an input that represents a search facet
     *
     * @param {string} fieldName The name of the facet input to return
     * @param {string=} fieldValue The value of the facet input to return
     * @returns {jQuery}
     */
    getFacetInput(fieldName, fieldValue = '') {
        let selector = `[name="${fieldName}"]`;
        if (fieldValue) {
            selector += `[value="${fieldValue}"]`;
        }
        return this.getRefinementForm().find(selector);
    }

    /**
     * Gets a collection of facet tags for a given fieldName regardless of the field's value(s)
     *
     * @param {string} fieldName
     * @returns {Array.<jQuery>}
     */
    getFacetTags(fieldName) {
        return $(`.js-search-facet-pill[data-facet-name="${fieldName}"]`);
    }

    /**
     * @returns {jQuery}
     */
    getRefinementForm() {
        return $(this.refinementFormSelector);
    }

    /**
     * @param {string} fieldName The name of the field to check
     * @returns {boolean} True means the field can only have one value and facet tag at a time
     */
    isSingular(fieldName) {
        return $.inArray(fieldName, getSingularFieldNames()) !== -1;
    }

    /**
     * Marshals a facet into a clickable button
     *
     * @param {string} fieldName The name of the input being marshaled
     * @param {string} fieldValue The value of the input being marshaled
     * @returns {jQuery} The search facet pill
     */
    marshalFacetTag(fieldName, fieldValue) {
        const $clone = $(this.facetTagTemplateMarkup).attr({
            'data-facet-name': fieldName,
            'data-facet-value': fieldValue,
        }); // need to use .attr instead of .data so we can use it as a selector

        let displayString = this.getFacetDisplayString(fieldName, fieldValue);

        if (this.isSingular(fieldName)) {
            if (displayString === undefined) {
                displayString = fieldName;
            }

            displayString = `${displayString} (${fieldValue})`;
        }

        $clone.find('.js-facet-name').text(displayString);
        return $clone;
    }

    /**
     * Removes a facet from the current search
     *
     * @param {string} fieldName The name of the facet input being removed
     * @param {string=} fieldValue The value of the facet input being removed
     * @param {boolean=} shouldTriggerChange True indicates the `change` event should be triggered
     */
    removeFacetTag(fieldName, fieldValue = '', shouldTriggerChange = false) {
        const $field = this.getFacetInput(fieldName, fieldValue);
        const isCustomValue = $field.hasClass('js-custom-facet-value');

        if (!isCustomValue && $field.prop('checked')) {
            $field.prop('checked', false);
        }

        if (shouldTriggerChange) {
            if (isCustomValue) {
                // For custom values, empty both the visible and hidden inputs.
                $field.closest('.js-facet-choice').find('.js-custom-facet-input').val('');
                $field.val('');
            }
            // Trigger a change so that the parent components can clean state and url.
            $field.trigger('change', [true]);
        }

        if (this.isSingular(fieldName) || !fieldValue) {
            // fields that can only have one value at a time should be removed regardless of what the value is...
            $(`.js-search-facet-pill[data-facet-name="${fieldName}"]`).remove();
        } else {
            $(`.js-search-facet-pill[data-facet-name="${fieldName}"][data-facet-value="${fieldValue}"]`).remove();
        }

        const $container = $(CONTAINER_SELECTOR);
        if (!$container.find('.js-search-facet-pill').length) {
            this.toggleFacetContainerVisibility(false);
        }
    }

    /**
     * Hides or shows facet summary
     *
     * @param {boolean} shouldShow Used to determine whether to show or hide facet summary
     */
    toggleFacetContainerVisibility(shouldShow) {
        $(CONTAINER_SELECTOR).toggleClass('hidden', !shouldShow);
    }
}

export default FacetSummaryView;
