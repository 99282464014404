import $ from 'jquery';

import AuthContext, { getRecaptchaActionsObject } from 'chairisher/context/auth';
import ModalFactory from 'chairisher/factory/modal';

import executeRecaptcha from 'chairisher/view/helper/recaptcha';

/**
 * Manages the view layer for seller contact
 */
class SellerContactView {
    createModal(bodyContent) {
        const $modal = ModalFactory.createModal('js-seller-contact-modal');

        $modal.addClass('seller-contact-modal');
        $modal.appendTo(document.body);
        $modal.find('.modal-body').html(bodyContent);
        $modal.on('hidden.bs.modal', function () {
            $(this).remove();
        });
        $modal.modal('show');
        return $modal;
    }

    /**
     * Submits contact form
     *
     * @param {jQuery} $form Form object to submit
     * @param {number} productId The ID of the product of interest
     */
    submit($form, productId) {
        const formData = $form.serializeArray();

        if (productId) {
            formData.push({
                name: 'product_id',
                value: productId,
            });
        }
        return executeRecaptcha(getRecaptchaActionsObject().SELLER_CONTACT).then((recaptchaData) => {
            if (recaptchaData) {
                const keys = Object.keys(recaptchaData);
                for (let i = 0; i < keys.length; i += 1) {
                    formData.push({
                        name: keys[i],
                        value: recaptchaData[keys[i]],
                    });
                }
            }
            return $.ajax({
                authUrl: `${AuthContext.getQuickCreateUrl('seller-contact')}&ea=${encodeURIComponent(
                    $form.find('[name="email"]').val(),
                )}`,
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: $.param(formData),
            }).done((response) => {
                const $modal = this.createModal(response.html);
                $modal.find('.js-close').one('click', (e) => {
                    e.preventDefault();
                    $modal.modal('hide');
                });
            });
        });
    }
}

export default SellerContactView;
