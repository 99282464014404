import $ from 'jquery';

/**
 * @returns {HTMLElement}
 */
function getRobotsMetaTag() {
    return $('meta[name=robots]')[0];
}

/**
 * Utility class (singleton) that assists with the modification of the META tags
 */
class MetaUtils {
    constructor() {
        /**
         * The original value of the `<meta name="robots">` HTML tag
         *
         * @type {string|null}
         */
        this.originalRobotsMetaTagContent = null;

        this.cacheOriginalRobotsMetaTag();
    }

    /**
     * @param {string} content The content to set in the robots tag
     */
    setRobotsMetaTag(content) {
        let robotsTag = getRobotsMetaTag();
        if (!robotsTag) {
            robotsTag = document.createElement('meta');
            robotsTag.name = 'robots';
            window.document.head.appendChild(robotsTag);
        }

        robotsTag.setAttribute('content', content);
    }

    /**
     * Restores the `<meta name="robots" content="<values>"` tag to what it was when the page loaded.
     * If there was no robots meta tag, then it will be removed.
     */
    restoreRobotsMetaTag() {
        const robotsMetaTag = getRobotsMetaTag();
        if (robotsMetaTag) {
            if (this.originalRobotsMetaTagContent === null || this.originalRobotsMetaTagContent === undefined) {
                robotsMetaTag.remove();
            } else {
                robotsMetaTag.content = this.originalRobotsMetaTagContent;
            }
        }
    }

    /**
     * Caches off the original value of the `<meta name="robots">` HTML tag
     */
    cacheOriginalRobotsMetaTag() {
        const robotsMetaTag = getRobotsMetaTag();
        if (robotsMetaTag) {
            this.originalRobotsMetaTagContent = robotsMetaTag.content;
        }
    }
}

export default new MetaUtils();
