const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to profile updates
 */
const ProfileContext = {
    /**
     * @return {string} URL of view which lists all images for a business
     */
    getBusinessImageListUrl() {
        return ChairishContext.BUSINESS_IMAGE_LIST_URL;
    },

    /**
     * List of specializations for this profile, if any.
     *
     * @return {Array.<string>}
     */
    getBusinessSpecializations() {
        return ChairishContext.BUSINESS_SPECIALIZATIONS || [];
    },

    /**
     * @returns {boolean} Whether the user has set a vacation period
     */
    getHasVacationSet() {
        return ChairishContext.HAS_VACATION_SET;
    },

    /**
     * @returns {string} URL of view which toggles vacation mode
     */
    getVacationToggleUrl() {
        return ChairishContext.VACATION_TOGGLE_URL;
    },

    /**
     * @param {boolean} hasVacationSet: Whether the user has set a vacation period
     */
    setHasVacationSet(hasVacationSet) {
        ChairishContext.HAS_VACATION_SET = !!hasVacationSet;
    },
};

export default ProfileContext;
