import $ from 'jquery';
import AlerterView from 'chairisher/view/alerter';
import ErrorContext from 'chairisher/context/error';
import FormFieldComponent from 'chairisher/component/form/field';
import LocationContext from 'chairisher/context/location';

const { countryCodes } = LocationContext;

/**
 * Logic for shared form methods
 */
const FormUtils = {
    /**
     * Add errors to form fields based on expected structure and classes
     */
    addFormErrors($form, errors) {
        $.each(errors, (fieldName, fieldErrors) => {
            $form.find(`.${fieldName}`).addClass('has-error').find('.js-errors').text(fieldErrors[0]);
        });

        const nonFieldErrorsKey = ErrorContext.getDjangoNonFieldErrorsKey();
        if (errors[nonFieldErrorsKey]) {
            const nonFormFieldErrors = errors[nonFieldErrorsKey].map((fieldError) =>
                $('<li></li>', { text: fieldError }),
            );

            const $nonFormFieldErrorsContainer = $('.js-non-field-form-errors');
            const $messagesList = $nonFormFieldErrorsContainer.find('.js-messages');
            if ($messagesList.length > 0) {
                $messagesList.append(nonFormFieldErrors);
                $nonFormFieldErrorsContainer.removeClass('hidden');
            } else {
                AlerterView.error($('<ul></ul>').append(nonFormFieldErrors));
            }
        }
    },

    /**
     * Clears errors on form fields
     */
    clearFormErrors($form) {
        $form.find('.js-errors').empty();
        $form.removeClass('has-error');
        $form.find('.has-error, .js-form-group').removeClass('has-error');
        $form.find('.js-non-field-form-errors .js-messages').empty();
        $form.find('.js-non-field-form-errors').addClass('hidden');
    },

    /**
     * Adds an error message to $formGroup when error message is truthy, removes it when falsy.
     *
     * @param {jQuery} $formGroup
     * @param {string|null} errorMessage
     */
    setFormGroupErrorMessage($formGroup, errorMessage) {
        const $errors = $formGroup.find('.js-errors');
        $formGroup.toggleClass('has-error', !!errorMessage);

        if (errorMessage) {
            $errors.html(`<ul class="errorlist"><li>${errorMessage}</li></ul>`);
        } else {
            $errors.html('');
        }
    },

    /**
     * Sets an inputmode attribute on an input element based on the specified country code
     *
     * @param {jQuery} $input The input element on which to set the inputmode attribute
     * @param {string} countryCode The two character country code
     */
    setInputmodeByCountryCode($input, countryCode) {
        $input.attr('inputmode', countryCode === countryCodes.us ? 'decimal' : 'text');
    },

    /**
     * Toggles disabled attr and class on form's submit button
     */
    toggleSubmitButton($form, shouldDisable) {
        const $submitButton = $form.find('button[type="submit"], .js-submit');
        $submitButton.prop('disabled', shouldDisable).toggleClass('disabled', shouldDisable);
    },
};

/**
 * Display errors from an XHR on a given form.
 * The errors are in a list as follows:
 *
 *  [
 *      {
 *          "field": "<field_name>",
 *          "message": "<error_message>",
 *      },
 *      {
 *          "field": "<field_name>",
 *          "message": "<error_message>"
 *      },
 *      ...
 *  ]
 *
 * @param {jqXHR} jqXHR the response
 * @param {jQuery} $form jQuery form object
 */

export function displayErrorsFromXhr(jqXHR, $form) {
    const errors = jqXHR?.responseJSON?.errors;
    if (errors) {
        const fieldToErrorMessages = {};
        errors.forEach((error) => {
            if (!fieldToErrorMessages[error.field]) {
                fieldToErrorMessages[error.field] = [];
            }

            fieldToErrorMessages[error.field].push({
                display: error.message,
            });
        });

        Object.entries(fieldToErrorMessages).forEach(([field, errorMessages]) => {
            $form
                .find(`[name=${field}]`)
                .closest('.js-form-group')
                .find('.js-errors')
                .html(FormFieldComponent.marshalMessageList(errorMessages));
        });
    }
}

/**
 * Binds focus events for an input group.
 * This allows us to outline the group container instead of the individual input on focus.
 *
 * @param $inputGroup {jQuery}
 */
export function bindInputGroupFocus($inputGroup) {
    $inputGroup.on('focus', 'input', () => $inputGroup.toggleClass('focused', true));
    $inputGroup.on('focusout', 'input', () => $inputGroup.toggleClass('focused', false));
}

export default FormUtils;
