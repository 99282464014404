import $ from 'jquery';
import ChairishUri from 'chairisher/util/uri';
import HistoryUtils from 'chairisher/util/history';

class ChairishState {
    constructor(initialOptions) {
        const options = { filterAndSortNames: null, ...initialOptions };

        /**
         * @type {Array.<string>}
         */
        this.filterAndSortNames = options.filterAndSortNames;
        this.state = {};

        this.updateStateFromUri();
    }

    /**
     * @returns {Object}
     */
    getState() {
        // Currently Safari and IE/Edge do not automatically restore scroll position
        // so we have to manage it manually.
        //
        // @see https://developer.mozilla.org/en-US/docs/Web/API/History (Compatibility)
        // @see https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration?hl=en (Explanation)
        // @see https://html.spec.whatwg.org/multipage/browsers.html#scroll-restoration-mode (WHATWG Spec)
        if (!window.history.scrollRestoration || window.history.scrollRestoration === 'manual') {
            this.state.scrollTop = $(window).scrollTop();
        }

        return this.state;
    }

    /**
     * @param {Array<string>} filterAndSortNames
     */
    setFilterAndSortNames(filterAndSortNames) {
        this.filterAndSortNames = filterAndSortNames;
    }

    /**
     * @param {string} key
     * @returns {string|Array|undefined} The state value for the provided key
     */
    getStateVal(key) {
        return this.state[key];
    }

    /**
     * @param {string} key
     * @param {string} val
     */
    setStateVal(key, val) {
        this.state[key] = val;
    }

    /**
     * @returns {string} The URL of the current state of the filter form
     */
    getStateUrl() {
        const queryStringParams = [];

        const isAcceptableNonArrayParam = (k, v) => v && $.inArray(k, this.filterAndSortNames) === -1;

        let hasAcceptableArrayParams = false;

        $.each(this.state, (key, values) => {
            // filter out states that don't have values for whatever reason
            // in addition to states that are only present in GET params...
            if (this.isAcceptableArrayParam(key, values)) {
                hasAcceptableArrayParams = true;
                if (Array.isArray(values)) {
                    for (let i = 0; i < values.length; i += 1) {
                        queryStringParams.push([key, values[i]]);
                    }
                } else {
                    queryStringParams.push([key, values]);
                }
            }
        });

        // clone queryStringParams up until this point to use for prepending /search/ to the current URL
        const params = ChairishUri.getParamMaps(document.location.search);

        // add back states that are only present in GET params...
        for (let i = 0; i < params.length; i += 1) {
            const param = params[i];
            if (isAcceptableNonArrayParam(param.name, param.value)) {
                queryStringParams.push([param.name, param.value]);
            }
        }

        let url = window.location.pathname;

        if (queryStringParams.length) {
            if (url !== '/search' && url.startsWith('/search') && !hasAcceptableArrayParams) {
                url = url.replace('/search', '');
            }
            url += `?${queryStringParams
                .map((param) => `${ChairishUri.encodeGetParam(param[0])}=${ChairishUri.encodeGetParam(param[1])}`)
                .join('&')}`;
        }

        return url;
    }

    isAcceptableArrayParam(k, v) {
        return v && k[0] !== '_' && $.inArray(k, this.filterAndSortNames) !== -1;
    }

    /**
     * Pushes a new state to window.history if available, otherwise it redirects to you a new page.
     *
     * @param {string=} stateUrl Optional string to use for the state URL
     */
    pushState(stateUrl) {
        HistoryUtils.pushState(this.getState(), document.title, stateUrl || this.getStateUrl());
    }

    /**
     * Removes an existing state to the state object
     *
     * @param {string} fieldName The name of the facet input being removed from the state object
     * @param {string} fieldValue The value of the facet input being removed from the state object
     * @param {boolean} shouldPersist True indicates the state should be persisted to window.history. Defaults to true.
     */
    removeState(fieldName, fieldValue, shouldPersist) {
        const currentVal = this.state[fieldName];

        if (currentVal) {
            if (fieldValue) {
                if (Array.isArray(currentVal)) {
                    currentVal.splice($.inArray(fieldValue, currentVal), 1);
                    if (currentVal.length === 0) {
                        delete this.state[fieldName];
                    }
                } else if (currentVal === fieldValue) {
                    delete this.state[fieldName];
                }
            } else {
                delete this.state[fieldName];
            }

            if (shouldPersist !== undefined ? !!shouldPersist : true) {
                this.pushState();
            }
        }
    }

    /**
     * @param {Object} stateObj
     */
    setStateObject(stateObj) {
        this.state = stateObj || {};
    }

    /**
     * Resets and updates state based on the values passed in the URI
     */
    updateStateFromUri() {
        const params = ChairishUri.getParamMaps(document.location.search);
        if (params.length) {
            $.each(params, (_, param) => {
                if (param.value !== undefined) {
                    let stateVal = this.getStateVal(param.name);
                    if (stateVal) {
                        if (!Array.isArray(stateVal)) {
                            stateVal = [stateVal];
                        }
                        stateVal.push(param.value);
                    }
                    this.setStateVal(param.name, stateVal || param.value);
                }
            });
        }
    }
}

export default ChairishState;
