import $ from 'jquery';
import { logShopAdClick, logShopAdImpression } from 'chairisher/analytics/promotedlistings';

import { CollectionPosition } from 'chairisher/util/analytics';
import { observeIntersectionOnce } from 'chairisher/view/helper/intersectionobserver';

const ShopAdAnalytics = {
    /**
     * Initializes analytics for shop ad click event listeners
     *
     * @param {string} trackingPosition represents where the ad is located on a given page
     */
    initializeShopAdClickListeners(trackingPosition) {
        const $shopAds = $('.js-shop-ad');
        $.each($shopAds, (i, el) => {
            $(el).on('click', 'a', (e) => {
                const $link = $(e.target);
                const $shopAd = $link.closest('.js-shop-ad');
                logShopAdClick(
                    $shopAd.data('shop-title'),
                    $link.attr('href'),
                    trackingPosition || `${CollectionPosition.BOTTOM_BANNER} (position ${i + 1})`,
                    $shopAd.data('dealer-guid'),
                );
            });
        });
    },

    /**
     * Initializes analytics for shop ad click event listeners and shop ad impressions
     *
     * @param {string} trackingPosition represents where the ad is located on a given page
     */
    initializeShopAdClickListenersAndImpressions(trackingPosition) {
        ShopAdAnalytics.initializeShopAdImpressions(trackingPosition);
        ShopAdAnalytics.initializeShopAdClickListeners(trackingPosition);
    },

    /**
     * Initializes analytics for logging shop ad impressions
     *
     * @param {string} trackingPosition represents where the ad is located on a given page
     */
    initializeShopAdImpressions(trackingPosition) {
        const $shopAds = $('.js-shop-ad');
        $.each($shopAds, (i, el) => {
            observeIntersectionOnce(el, (entry) => {
                const $ad = $(entry.target);
                const dealerGuid = $ad.data('dealer-guid');
                const analyticsPosition = trackingPosition || `${CollectionPosition.BOTTOM_BANNER} (position ${i + 1})`;
                logShopAdImpression(analyticsPosition, dealerGuid);
            });
        });
    },
};

export default ShopAdAnalytics;
