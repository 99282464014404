/**
 * A facet that may or may not contain sub-facet (i.e., may or may not be heterogeneous).
 * @typedef {Object} Facet
 * @property {string} key
 * @property {string} label
 * @property {?Array.<FacetChoice>} count_dicts
 * @property {?Boolean} is_range
 */

/**
 * A facet choice that may or may not contain sub-choices
 * @typedef {Object} FacetChoice
 * @property {?Array.<FacetChoice>}
 * @property {boolean} is_multi_select
 * @property {?number} count
 * @property {string} key
 * @property {string} label
 * @property {string} value
 * @property {?boolean} is_location_required Whether a 'location' parameter must be sent to the server when selected
 */

/**
 * A data structure containing all relevant information needed to render a dimension group
 * @typedef {Object} DimensionGroup
 * @property {Array.<string>} categories The categories for which the dimension filters are relevant
 * @property {Array.<DimensionFilter>} filters The data structures containing info needed to render dimension filters
 */

/**
 * A data structure containing all relevant information needed to render a dimension filter
 * @typedef {Object} DimensionFilter
 * @property {string} key The key under which the data should be submitted to the server
 * @property {string} label The human readable string used to display the filter's name
 * @property {number} max The maximum value for which the filter can be assigned
 * @property {number} min The minimum value for which the filter can be assigned
 */

import $ from 'jquery';

import { FacetTagNames, getPriceFacetFieldNames } from 'chairisher/context/search';
import { intcomma } from 'chairisher/util/format';

/**
 * A simple helper which marshals a tree of facets
 */
class FacetBuilderView {
    constructor() {
        /**
         * @type {string}
         */
        this.facetMarkup = $('#js-template-facet').html();

        /**
         * @type {string}
         */
        this.facetChoiceMarkup = $('#js-template-facet-choice').html();

        /**
         * @type {string}
         */
        this.facetChoiceGroupMarkup = $('#js-template-facet-choice-group').html();

        /**
         * @type {string}
         */
        this.facetChoiceLocationMarkup = $('#js-template-facet-choice-location').html();

        /**
         * @type string
         */
        this.priceRangePickerMarkup = $('#js-template-price-range-picker').html();

        /**
         * @type string
         */
        this.dimensionRangeContainerMarkup = $('#js-template-dimension-range').html();

        /**
         * @type string
         */
        this.dimensionRangeChoiceMarkup = $('#js-template-dimension-range-choice').html();

        /**
         * Array of facet values where 'is_location_required' is true.
         *
         * @type {Array.<string>}
         */
        this.facetsThatRequireLocation = [];
    }

    /**
     * @param {string} facetValue The facet value to check
     * @returns {boolean}
     */
    isLocationRequired(facetValue) {
        return this.facetsThatRequireLocation.includes(facetValue);
    }

    /**
     * Marshals a `facet` DOM structure
     *
     * @param {Facet} facet
     * @param {boolean} isExpanded
     */
    marshalFacet(facet, isExpanded) {
        const $facet = $(this.facetMarkup);

        $facet.attr('data-key', facet.key);
        $facet.find('.js-facet-title-text').text(facet.label);

        if (facet.key === 'art_orientation') {
            $facet.addClass('facet-art-orientation');
        }

        if (isExpanded) {
            $facet.find('.js-facet-title').attr({
                'aria-expanded': isExpanded,
                'data-parent': '#js-facet-category-tree',
                'data-toggle': 'collapse',
                'text': facet.label,
            });
        }

        const $clearAllLink = $('<a></a>', {
            class: 'js-clear-all facet-action',
            href: '#',
            text: 'Clear All',
        });

        const $clearAllChoice = $('<li></li>', {
            class: 'facet-choice js-facet-choice',
            role: 'tab',
        }).append($clearAllLink);

        if (facet.count_dicts) {
            const $facetChoices = this.marshalFacetChoices(facet.count_dicts, isExpanded);
            if (getPriceFacetFieldNames().includes(facet.key)) {
                // Add the custom min/max inputs for the price facet.
                const $priceRangePicker = $(this.priceRangePickerMarkup);
                $priceRangePicker.find('.js-facet-input').attr({ name: facet.key });
                $facetChoices.append($priceRangePicker);
            }
            $facetChoices.append($clearAllChoice);
            $facet.find('.js-facet-choices').replaceWith($facetChoices);
        }

        return $facet;
    }

    /**
     * @returns {jQuery}
     */
    marshalDimensionFilterContainer(isExpanded) {
        const $facet = $(this.dimensionRangeContainerMarkup);
        const $facetChoices = $facet.find('.js-facet-choices');
        const $title = $facet.find('.js-facet-title');
        const $titleText = $title.find('.js-facet-title-text');

        $facet.addClass('js-dimension-facet hidden');
        $facetChoices.addClass('js-dimension-group-container');
        $title.attr('aria-expanded', isExpanded);
        $titleText.text('Dimensions');

        if (!isExpanded) {
            $facetChoices.addClass('collapse');
        }

        return $facet;
    }

    /**
     * @param {DimensionFilter} filter
     * @returns {jQuery} An input for selecting min/max width, height, or depth
     */
    marshalDimensionFilter({ label, key }) {
        const $filter = $(this.dimensionRangeChoiceMarkup);
        $filter.attr({ 'data-key': key });
        $filter.find('.js-custom-facet-input-label').text(label);
        $filter.find('.js-facet-input').attr({ name: key });
        return $filter;
    }

    /**
     * Marshals a `facet choice` DOM structure
     *
     * @param {FacetChoice} facetChoice
     */
    marshalFacetChoice(facetChoice) {
        let $facetChoice = $(this.facetChoiceMarkup);
        const label = facetChoice.label.toLowerCase();

        const $choiceLabel = $facetChoice.find('.js-facet-choice-label');
        $choiceLabel.attr('data-display-string', label);

        const $facetInput = $facetChoice.find('.js-facet-input');
        $facetInput.attr({
            'data-is-multi-select': facetChoice.is_multi_select,
            'name': facetChoice.key,
            'type': 'checkbox',
            'value': facetChoice.value,
        });

        const $facetCopy = $facetChoice.find('.js-facet-copy');
        const desc = facetChoice.desc ? `: ${facetChoice.desc}` : '';

        if (facetChoice.key === FacetTagNames.RADIUS) {
            $facetChoice = this.marshalRadiusFacetChoice(facetChoice, $facetChoice);
        } else if (facetChoice.count || facetChoice.count === 0) {
            $facetCopy.html(
                `${facetChoice.label}${desc}<span class="facet-count">(${intcomma(facetChoice.count)})</span>`,
            );
        } else {
            $facetCopy.text(`${facetChoice.label}${desc}`);
        }

        if (facetChoice.key === 'colors') {
            $facetChoice.addClass('facet-choice-color');
            $choiceLabel.addClass('facet-label-color');
            const $color = $('<span></span>', { class: 'color' });
            if (facetChoice.is_extra_color) {
                $color.css('background-color', `#${facetChoice.value}`);
            } else {
                $color.addClass(`color-${facetChoice.value.toLowerCase()}`);
            }
            $color.insertBefore($choiceLabel.find('.cicon'));

            $facetChoice.find('.js-cicon').remove();
        } else if (facetChoice.key === 'art_orientation') {
            $facetCopy.addClass('sr-only');
            $facetChoice.find('.js-cicon').removeClass('cicon-checkmark-fill').addClass(`cicon-${label}`);
            $choiceLabel.addClass(`facet-label-orientation art-orientation-${label}`);
        } else if (facetChoice.key === 'ring_size_filter') {
            $facetChoice.addClass('facet-choice-tile');
            $choiceLabel.addClass('facet-label-tile');
            const $ringSize = $('<span></span>', { class: 'facet-tile' });
            $ringSize.insertBefore($choiceLabel.find('.js-cicon'));
            $facetChoice.find('.js-cicon').remove();
        }

        if (facetChoice.is_location_required) {
            this.facetsThatRequireLocation.push(facetChoice.value);
        }

        return $facetChoice;
    }

    /**
     * Marshals a `facet choice group` DOM structure
     *
     * @param {FacetChoice} facetChoice
     * @returns {jQuery}
     */
    marshalFacetChoiceGroup(facetChoice) {
        const label = facetChoice.label.toLowerCase();
        const $facetChoiceGroup = $(this.facetChoiceGroupMarkup);
        const $choiceGroupTitle = $facetChoiceGroup.find('.js-facet-choice-group-title');
        if (facetChoice.count || facetChoice.count === 0) {
            $choiceGroupTitle.html(
                `${facetChoice.label}<span class="facet-count">(${intcomma(facetChoice.count)})</span>`,
            );
        } else {
            $choiceGroupTitle.text(facetChoice.label);
        }

        $facetChoiceGroup.find('.js-facet-input').attr({
            'data-is-multi-select': facetChoice.is_multi_select,
            'name': facetChoice.key,
            'type': 'checkbox',
            'value': facetChoice.value,
        });

        $facetChoiceGroup.find('.js-facet-choice-label').attr('data-display-string', label);
        $facetChoiceGroup.find('.js-facet-choices').replaceWith(this.marshalFacetChoices(facetChoice.children));

        return $facetChoiceGroup;
    }

    /**
     * Marshals a collection of facets and their children into DOM structures
     *
     * @param {Array.<Object>} facetChoices
     * @param {boolean|null} isExpanded
     * @returns {jQuery}
     */
    marshalFacetChoices(facetChoices, isExpanded = null) {
        const $facetList = $('<ol></ol>', {
            'class': 'facet-choices js-facet-choices',
            'role': 'tablist',
            'aria-multiselectable': 'true',
        });

        if (isExpanded !== null) {
            $facetList.attr({ 'aria-expanded': isExpanded });
            $facetList.addClass('collapse');

            if (isExpanded) {
                $facetList.addClass('in');
            }
        }

        let isTileChoices = false;
        facetChoices.forEach((facetChoice) => {
            if (facetChoice.key === FacetTagNames.LOCATION) {
                $facetList.append(this.marshalLocationFacetChoice(facetChoice));
            } else if (facetChoice.children && facetChoice.children.length) {
                $facetList.append(this.marshalFacetChoiceGroup(facetChoice));
            } else {
                $facetList.append(this.marshalFacetChoice(facetChoice));
            }

            if (facetChoice.key === 'ring_size_filter') {
                isTileChoices = true;
            }
        });

        if (isTileChoices) {
            $facetList.addClass('facet-choice-tiles-container');
        }

        return $facetList;
    }

    /**
     * Renders a facet with an input for selecting country/postal code
     *
     * @param {FacetChoice} facetChoice
     * @returns {jQuery}
     */
    marshalLocationFacetChoice({ country_code: countryCode, key, label, value }) {
        const $locationFacetChoice = $(this.facetChoiceLocationMarkup);
        $locationFacetChoice.addClass(`js-facet-choice-${key} facet-choice-${key}`);

        const $locationBtn = $locationFacetChoice.find('.js-location-btn');
        $locationBtn.data('country-code', countryCode);
        $locationBtn.val(value);
        if (!value) {
            $locationBtn.text(label);
        }

        return $locationFacetChoice;
    }

    /**
     * "Fixes up" a facetChoice with radius-specific info and a select widget
     *
     * @param {FacetChoice} facetChoice
     * @param {jQuery} $facetChoice
     * @returns {jQuery}
     */
    marshalRadiusFacetChoice(facetChoice, $facetChoice) {
        const $facetCheckbox = $facetChoice.find('.js-cicon');
        const $facetCopy = $facetChoice.find('.js-facet-copy');
        const $facetInput = $facetChoice.find('.js-facet-input');

        // removes the checkbox option from the user
        $facetCheckbox.addClass('hidden');
        $facetInput.prop('disabled', true);

        // NOTE: This is hardcoded and ugly. If a better abstraction arises, please use it.
        $facetCopy.html('Within <select></select> miles');

        const $radiusSelect = $facetCopy.find('select');

        const validDistances = facetChoice.valid_distances;
        const isRadiusValid = $.inArray(facetChoice.value, validDistances) !== -1;
        const selectedRadius = isRadiusValid ? facetChoice.value : facetChoice.default_radius;

        $.each(validDistances, (i, distance) => {
            const $option = $('<option></option>', {
                text: distance,
                value: distance,
            });

            $radiusSelect.append($option);
        });

        $radiusSelect.val(selectedRadius);
        $facetInput.val(selectedRadius);
        if (isRadiusValid) {
            $facetInput.prop('checked', true);
        }

        $facetChoice.addClass('js-facet-choice-radius facet-choice-radius hidden');

        return $facetChoice;
    }
}

export default FacetBuilderView;
