/**
 * Utility functions for internationalization and localization (i18n) related tasks.
 */

const CM_PER_INCH = 2.54;

export const DIMENSION_UNITS = Object.freeze({
    CENTIMETERS: 'cm',
    INCHES: 'in',
});

/**
 * @param {Number} inches
 * @returns {Number} The amount of centimeters in the given inches.
 */
export function toCentimeters(inches) {
    return Math.round(inches * CM_PER_INCH);
}

/**
 * @param {Number} centimeters
 * @returns {Number} The amount of inches in the given centimeters.
 */
export function toInches(centimeters) {
    return Math.round(centimeters / CM_PER_INCH);
}
