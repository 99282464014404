const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to the Google Places & Geocoding APIs, etc.
 * @see https://developers.google.com/maps/documentation/places/web-service
 * @see https://developers.google.com/maps/documentation/geocoding
 */
/**
 * @returns {string} The url for the Google Place Autocomplete API
 */
export function getPlacesAutocompleteUrl() {
    return ChairishContext.PLACES_AUTOCOMPLETE_URL;
}

/**
 * @returns {string} The url for the Google Place Detail API
 */
export function getPlacesDetailedAddressUrl() {
    return ChairishContext.PLACES_DETAILED_ADDRESS_URL;
}

/**
 * @returns {string} The url for obtaining postal code suggestions from the Google Place Autocomplete API
 */
export function getPostalCodeAutocompleteUrl() {
    return ChairishContext.POSTAL_CODE_AUTOCOMPLETE_URL;
}

/**
 * @returns {string} The url for checking the validity of a postal code
 */
export function getPostalCodeLookupUrl() {
    return ChairishContext.POSTAL_CODE_LOOKUP_URL;
}

/**
 * @returns {Object} Object containing src, srcset, and sizes attributes for the Google text logo
 */
export function getPoweredByGoogleLogoFormats() {
    return ChairishContext.POWERED_BY_GOOGLE_LOGO_FORMATS;
}

/**
 * @return {string} The url for the "powered by Google" logo
 */
export function getPoweredByGoogleLogoUrl() {
    return ChairishContext.POWERED_BY_GOOGLE_LOGO_URL;
}

/**
 * @return {string} The url for the Google Verify Address Geocoding API
 */
export function getVerifyAddressUrl() {
    return ChairishContext.VERIFY_ADDRESS;
}

/**
 * This hasn't been enabled since 2022, so care should be taken if it's re-enabled to verify features still work.
 *
 * @returns {boolean} True indicates the address autocomplete feature is enabled; false, if not
 */
export function isAddressAutocompleteEnabled() {
    return !!ChairishContext.IS_ADDRESS_AUTOCOMPLETE_ENABLED;
}

/**
 * This hasn't been enabled since 2022, so care should be taken if it's re-enabled to verify features still work.
 *
 * @returns {boolean} True indicates the address verification feature is enabled; false, if not
 */
export function isAddressVerificationEnabled() {
    return !!ChairishContext.IS_ADDRESS_VERIFICATION_ENABLED;
}

/**
 * This hasn't been enabled since 2022, so care should be taken if it's re-enabled to verify features still work.
 *
 * @returns {boolean} True indicates the postal code autocomplete feature is enabled; false, if not
 */
export function isPostalCodeAutocompleteEnabled() {
    return !!ChairishContext.IS_POSTAL_CODE_AUTOCOMPLETE_ENABLED;
}
