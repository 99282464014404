import { AmplitudeEventProperty, logAmplitudeEvent, logAmplitudeLinkClickEvent } from 'chairisher/util/analytics';

/**
 * Track a click event on the folder trigger
 *
 * @param {jQuery} $form The jQuery form submitted to trigger this event
 * @param {FolderCardPosition} position The position in which the folder trigger was clicked
 */
export function trackCreateFolder($form, position) {
    const productIds = $form.find('[name=product_ids]').val();
    const amplitudeEventObject = {
        [AmplitudeEventProperty.CTA_NAME]: 'Create New Folder',
        [AmplitudeEventProperty.CTA_POSITION]: position,
        [AmplitudeEventProperty.CTA_URL]: $form.attr('action'),
    };

    if (productIds) {
        amplitudeEventObject[AmplitudeEventProperty.PRODUCT_IDS] = productIds;
    }

    logAmplitudeEvent('folder - create', amplitudeEventObject);
}

/**
 * Track a click event on the folder trigger
 *
 * @param {Object} e The event fired
 * @param {FolderCardPosition} position in which the folder trigger was clicked
 */
export function trackFolderTriggerClick(e, position) {
    logAmplitudeLinkClickEvent('View Folders', '/folder/list', position);
}
