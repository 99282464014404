const ImageUtils = {
    /**
     * Collection of valid mime types that can be used for uploading images
     *
     * @type {Array.<string>}
     */
    VALID_MIME_TYPES: ['image/gif', 'image/jpeg', 'image/png'],

    /**
     * Returns an object that can be used to marshal an image given an image format object
     * from the `get_image_format_dict` template tag.
     *
     * @param {string} sizedBaseUrl The base URL for a sized image
     * @param {Object} formatObj Object declaring aspect ratio, sizes, and srcset data
     * @returns {{sizes: string, src: string, srcset: string}}
     *
     * @see chairisher/templatetags/image_filters.py:get_image_format_dict
     */
    generateSrcsetObj(sizedBaseUrl, formatObj) {
        const generateFormattedString = (formats, isSrcset) =>
            formats.map(
                (obj) =>
                    `${sizedBaseUrl}?${[`aspect=${obj.aspect}`, `width=${obj.width}`, `height=${obj.height}`].join(
                        '&',
                    )}${isSrcset ? ` ${obj.width}w` : ''}`,
            );

        return {
            sizes: formatObj.sizes,
            src: generateFormattedString([formatObj.src]),
            srcset: generateFormattedString(formatObj.srcset, true),
        };
    },
};

export default ImageUtils;
