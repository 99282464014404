import $ from 'jquery';

/**
 * A choice that may or may not contain sub-choices
 * @typedef {Object} Choice
 * @property {string} display
 * @property {string|number} value
 * @property {Object} [image]
 * @property {string} [image.src]
 * @property {string} [image.srcset]
 * @property {string} [image.sizes]
 * @property {string} [url]
 */

/**
 * Values that can be used to indicate ui elements that should be added on to the pill based on its value
 *
 * @enum
 */
export const PillAddOnType = {
    SWATCH: 'swatch',
};

/**
 * String used to segregate events related to the pill into a separate namespace
 */
const eventNamespace = 'pill';

/**
 * Widget that displays choices as a pill, that can be grouped together or not, and deselected at will
 *
 * @param {Object} settings
 * @param {PillAddOnType=} settings.dataAddOnType The add-on type that should be rendered with this pill
 * @param {string=} settings.dataDisplayKey The key in the Choice data whose value should be displayed
 * @param {Choice=} settings.value The Choice data to be visualized by this component
 */
export default class Pill {
    constructor({ dataAddOnType = null, dataDisplayKey = 'display', value = null }) {
        this.dataAddOnType = dataAddOnType;
        this.dataDisplayKey = dataDisplayKey;
        this.value = value;
        this.$el = this.#marshalPillMarkup();
        this.$el.data('pill', this);
    }

    /**
     * Binds actions to the various elements that comprise a pill
     */
    bind() {
        // clicking anywhere on the pill removes it
        this.$el.on('mousedown', (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.isEnabled()) {
                this.remove();
            }
        });
    }

    /**
     * Disables the Pill so it cannot be removed
     */
    disable() {
        this.$el.addClass('disabled');
    }

    /**
     * Enables the Pill so it can be removed
     */
    enable() {
        this.$el.removeClass('disabled');
    }

    /**
     * @returns {jQuery} The jQuery pill element
     */
    getEl() {
        return this.$el;
    }

    /**
     * @returns {Choice} The value of the Pill
     */
    getValue() {
        return this.value;
    }

    /**
     * @returns {boolean} Flag indicating if the Pill is enabled and can be removed
     */
    isEnabled() {
        return !this.$el.hasClass('disabled');
    }

    /**
     * Removes the pill element from the DOM and triggers an event so listeners can react
     */
    remove() {
        this.$el.trigger(`${eventNamespace}:remove`, [this.value]);
        this.$el.remove();
    }

    /**
     * Create a new jQuery representation of a pill
     */
    #marshalPillMarkup() {
        const $pill = $('<span></span>', {
            class: 'pill js-pill',
        });

        const choice = this.value;
        const { value } = choice;

        const $pillText = $('<span></span>', {
            class: 'pill-text',
            text: choice[this.dataDisplayKey] || value,
        });

        const $ciconX = $('<span></span>', {
            class: 'cicon cicon-x js-dismiss',
        });

        const $pillValue = $('<span></span>', {
            class: 'pill-value',
        });

        $pillValue.append($pillText, $ciconX);

        if (this.dataAddOnType === PillAddOnType.SWATCH) {
            $pillValue.prepend(
                $('<span></span>', {
                    class: 'swatch',
                    style: `background-color: #${value}`,
                }),
            );
        }

        $pill.append($pillValue);
        $pill.attr('data-value', value);

        return $pill;
    }
}
