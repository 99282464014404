import $ from 'jquery';
import 'bootstrap'; // needs to be after jQuery

import FormUtils from 'chairisher/util/form';
import LocationContext from 'chairisher/context/location';
import Popover from 'chairisher/component/popover';
import UriUtils from 'chairisher/util/uri';

import { getPostalCodeLookupUrl } from 'chairisher/context/google';

/**
 * Events that can be emitted from the LocationPopover
 * @enum {string}
 */
const LocationPopoverEvents = Object.freeze({
    ERROR: 'locationpopover:error',
    SUCCESS: 'locationpopover:success',
});

/**
 * Component that prompts for location (country code / postal code) in a popover and fires a callback after submitting
 *
 * @param {Object=} settings Object containing settings to configure the LocationPopover
 * @param {jQuery} settings.$el The jQuery element to bind the popover to
 * @param {string=} settings.contentTemplateSelector Optional selector to use to populate the popover with content
 * @param {string=} settings.countryCode Optional country code to provide as a default when the popover is shown
 * @param {string=} settings.postalCode Optional postal code to provide as a default when the popover is shown
 */
class LocationPopover extends Popover {
    constructor({ $el, countryCode = '', postalCode = '', contentTemplateSelector = '#js-location-popover-template' }) {
        super({ $el, containerClass: 'location-popover', eventNamespace: 'locationPopover' });

        this.countryCode = countryCode;
        this.postalCode = postalCode;
        this.contentTemplateSelector = contentTemplateSelector;

        this.setContent(this.getContent());
    }

    /**
     * Binds everything related to the popover. If you need to reverse this call `destroy`.
     *
     * @param {string=} placement Allows for customization of popover placement relative to where it's opened
     *
     * @see destroy
     * @see https://getbootstrap.com/docs/3.4/javascript/#popovers-options
     */
    bind(placement) {
        super.bind(placement);

        this.$el.on('inserted.bs.popover', () => {
            const $body = $(document.body);
            const $locationPopover = $body.find(this.selector);
            const $countrySelect = $locationPopover.find('[name="country_code"]');
            const $postalCodeInput = $locationPopover.find('[name="postal_code"]');
            const $submitBtn = $locationPopover.find('button[type="submit"]');

            if (this.countryCode) {
                $countrySelect.val(this.countryCode);
                FormUtils.setInputmodeByCountryCode($postalCodeInput, this.countryCode);
            }

            if (this.postalCode) {
                $postalCodeInput.val(this.postalCode);
                $submitBtn.prop('disabled', true);
            }

            // auto-focuses (and selects, if postal code is present) for smoother UX when updating location
            const postalCodeInput = $postalCodeInput[0];
            postalCodeInput.focus({ preventScroll: true });
            postalCodeInput.select();

            // removes disabling of submit button if value of country_code or postal_code is changed
            $countrySelect.on('change', (e) => {
                $submitBtn.prop('disabled', false);
                FormUtils.setInputmodeByCountryCode($postalCodeInput, $(e.currentTarget).val());
            });
            $postalCodeInput.on('keyup', (e) => {
                if ($submitBtn.prop('disabled')) {
                    const inputVal = $(e.currentTarget).val();
                    const locationVal = UriUtils.extractParamFromUri(document.location.search, 'location');

                    if (inputVal !== locationVal) {
                        $submitBtn.prop('disabled', false);
                    }
                }
            });

            $body.on(`submit.${this.eventNamespace}`, `${this.selector} form`, (e) => {
                e.preventDefault();
                this.submit($(e.currentTarget).serialize());
            });
        });
    }

    /**
     * Returns the content used to populate the popover. If you need to update the content call `reset`
     *
     * @returns {string} The content used to populate the popover
     * @see reset
     */
    getContent() {
        if (!this.content) {
            const $content = $($(this.contentTemplateSelector).html());

            const options = LocationContext.getShippableCountryChoices().map(({ display, value }) =>
                $('<option></option>', {
                    text: display,
                    value,
                }),
            );

            const $countryCode = $content.find('[name="country_code"]');
            $countryCode.html(options);

            if (this.postalCode) {
                $content.find('[name="postal_code"]').attr('value', this.postalCode);
            }
            const formObj = $content.get(0);
            this.content = formObj ? formObj.outerHTML : null;
        }

        return this.content;
    }

    /**
     * Resets the popover. After calling `reset` this.content will be regenerated the next time `show` is called
     */
    reset() {
        this.content = null;
        this.countryCode = '';
        this.postalCode = '';
    }

    /**
     * @param {string} countryCode 2 character country code
     */
    setCountryCode(countryCode) {
        this.countryCode = countryCode;
    }

    /**
     * @param {string} postalCode
     */
    setPostalCode(postalCode) {
        this.postalCode = postalCode;
    }

    /**
     * Submits country code and postal code to the backend to validate.
     *
     * Fires `locationpopover:success` and `locationpopover:error` events as appropriate.
     *
     * @param {string|Object|Array} data
     * @param {string} data.country_code
     * @param {string} data.postal_code
     * @returns $.Deferred
     */
    submit(data) {
        return $.ajax({
            data,
            method: 'post',
            url: getPostalCodeLookupUrl(),
        })
            .done((responseData) => {
                this.trigger(LocationPopoverEvents.SUCCESS, responseData.postal_code_lookup_response);
            })
            .fail((response) => {
                this.trigger(LocationPopoverEvents.ERROR, response.responseJSON.errors);
            });
    }

    /**
     * Triggers an event via `this.$el`.
     *
     * @param {LocationPopoverEvents} type The event type to emit
     * @param {Object=} data Optional data object to provide to listeners
     */
    trigger(type, data) {
        this.$el.trigger($.Event(type), data);
    }
}

export default { LocationPopover, LocationPopoverEvents };
