import $ from 'jquery';
import 'bootstrap'; // needs to be after jQuery
import KeyUtils from 'chairisher/util/key';

/**
 * Popover with a close button
 *
 * @param {Object=} settings Object containing settings to configure the popover
 * @param {jQuery} settings.$el The jQuery element to bind the popover to
 * @param {string} settings.containerClass The selector class of the popover container
 * @param {string} settings.eventNamespace A namespace for the bound events
 */
class Popover {
    constructor({ $el, containerClass, eventNamespace }) {
        this.$el = $el;
        this.containerClass = containerClass;
        this.eventNamespace = eventNamespace;
        this.selectorClass = `js-${this.containerClass}`;
        this.selector = `.${this.selectorClass}`;
    }

    /**
     * Binds everything related to the popover. If you need to reverse this call `destroy`.
     *
     * @param {string=} placement Allows for customization of popover placement relative to where it's opened
     *
     * @see destroy
     * @see https://getbootstrap.com/docs/3.4/javascript/#popovers-options
     */
    bind(placement) {
        this.$el.popover({
            content: () => this.content,
            container: 'body',
            html: true,
            placement: placement || 'bottom',
            template: `
                <div class="popover ${this.containerClass} ${this.selectorClass}" role="tooltip">
                  <div class="arrow"></div>
                  <div class="btn-close-container">
                      <button class="btn btn-link btn-close js-close" type="button">
                        <span class="cicon cicon-close-stroke"></span>
                      </button>
                  </div>
                  <div class="popover-content"></div>
                </div>
            `,
            trigger: 'manual',
        });

        this.$el.on('inserted.bs.popover', () => {
            this.unbind(); // unbinds events upon opening popover to avoid double-binding

            const $body = $(document.body);

            // closes the popover when clicking the close button
            $body.on(`click.${this.eventNamespace}`, `${this.selector} .js-close`, () => {
                this.unbind();
                this.hide();
            });

            // closes the popover when clicking outside it
            $body.on(`click.${this.eventNamespace}`, (e) => {
                const $target = $(e.target);
                if (
                    !(
                        $target.is(this.$el) ||
                        $target.is(this.selector) ||
                        $target.closest(this.selector).length > 0 ||
                        $target.hasClass(`${this.selectorClass}-child`)
                    )
                ) {
                    this.unbind();
                    this.hide();
                }
            });

            // closes the popover when pressing the Escape key
            $body.on(`keydown.${this.eventNamespace}`, (e) => {
                if (KeyUtils.isEscape(e.which)) {
                    this.unbind();
                    this.hide();
                }
            });
        });
    }

    /**
     * Destroys the popover and unbinds everything related to it. If you need to reverse this call `bind`.
     *
     * @see bind
     */
    destroy() {
        this.$el.popover('destroy');
        this.unbind();
    }

    /**
     * @param {string} content The HTML content used to populate the popover
     */
    setContent(content) {
        this.content = content;
    }

    /**
     * Hides the popover
     *
     * @see show
     */
    hide() {
        this.$el.popover('hide');
    }

    /**
     * Shows the popover
     *
     * @see hide
     */
    show() {
        this.$el.popover('show');
    }

    /**
     * Unbinds any events with the specified namespace.
     */
    unbind() {
        $(document.body).off(`.${this.eventNamespace}`);
    }
}

export default Popover;
