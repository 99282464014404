import $ from 'jquery';

import { hasIntersectionObserver } from 'chairisher/view/helper/intersectionobserver';

/**
 * A component that turns an element into a Scroller, which cycles through the elements
 * in either direction until the end is hit.
 *
 * @param {Object} settings
 * @param {Element} settings.container The outermost element that scrolls
 * @param {Array.<Element>} settings.children Collection of child elements displayed in the scroller
 *
 * @class Scroller
 * @constructs Scroller
 */
const Scroller = function (settings) {
    settings = $.extend(
        {
            container: this._containerEl,
            containerBody: this._containerBodyEl,
            children: this._children,
        },
        settings,
    );

    this._containerEl = settings.container;
    this._containerBodyEl = settings.containerBody;
    this._children = settings.children;

    this._$containerEl = $(this._containerEl);
    this._$containerBodyEl = $(this._containerBodyEl);
    this._$windowEl = $(window);

    this._$windowEl.smartresize($.proxy(this._resizeAction, this), this._nameSpacedEvent('resize'));

    this._initializeChildren(settings.children);

    this._buildControls();

    this._isInitialized = true;
};

/**
 * jQuery instance of `this._containerBodyEl` to provide for easier binding
 *
 * @type {jQuery}
 * @private
 */
Scroller.prototype._$containerBodyEl = null;

/**
 * jQuery instance of `this._containerEl` to provide for easier binding
 *
 * @type {jQuery}
 * @private
 */
Scroller.prototype._$containerEl = null;

/**
 * jQuery instance of the left controller, if created
 *
 * @type {jQuery|null}
 * @private
 */
Scroller.prototype._$controllerLeftEl = null;

/**
 * jQuery instance of the right controller, if created
 *
 * @type {jQuery|null}
 * @private
 */
Scroller.prototype._$controllerRightEl = null;

/**
 * jQuery instance of `window` to provide for easier binding
 *
 * @type {jQuery}
 * @private
 */
Scroller.prototype._$windowEl = null;

/**
 * Mapping of child index in `this._children` to the element's `boundingClientRect`
 * @type {Object}
 * @private
 */
Scroller.prototype._childData = null;

/**
 * Collection of child elements displayed in the scroller
 *
 * @type {Array.<Element>}
 * @private
 */
Scroller.prototype._children = null;

/**
 * The outermost element
 *
 * @type {Element}
 * @private
 */
Scroller.prototype._containerEl = null;

/**
 * The element that scrolls
 *
 * @type {Element}
 * @private
 */
Scroller.prototype._containerBodyEl = null;

/**
 * The current index that is scrolled to
 *
 * @type {number}
 * @private
 * @default
 */
Scroller.prototype._currentIndex = 0;

/**
 * The namespace under which events should be bound
 *
 * @type {string}
 * @private
 * @default
 */
Scroller.prototype._eventNameSpace = 'scroller';

/**
 * Flag used to determine if all of the elements have been initialized and are ready for use
 *
 * @type {boolean}
 * @private
 * @default
 */
Scroller.prototype._isInitialized = false;

/**
 * A handle to cancel the current resize action (if any).
 *
 * @type {number|null}
 * @private
 */
Scroller.prototype._resizeHandle = null;

/**
 * Scrolls the container backward by one element index
 */
Scroller.prototype.scrollBackward = function () {
    this._updateCurrentIndexByContainerPosition();

    const previousChild = this._getChildByIndex(this._currentIndex - 1);
    if (previousChild && !this._isScrolling) {
        this._currentIndex--;
        this._scrollContainer(this._getChildScrollLeftByIndex(this._currentIndex));
    }
};

/**
 * Scrolls the container forward by one element index
 */
Scroller.prototype.scrollForward = function () {
    this._updateCurrentIndexByContainerPosition();

    const nextChild = this._getChildByIndex(this._currentIndex + 1);
    if (nextChild && !this._isScrolling) {
        this._currentIndex++;
        this._scrollContainer(this._getChildScrollLeftByIndex(this._currentIndex));
    }
};

/**
 * Builds clickable left and right controls that scroll the container forward and backward
 *
 * @private
 */
Scroller.prototype._buildControls = function () {
    this._$controllerLeftEl = $('<span></span>', {
        class: 'cicon cicon-chevron-left js-scroll-left',
    });

    this._$controllerRightEl = $('<span></span>', {
        class: 'cicon cicon-chevron-right js-scroll-right',
    });

    this._updateControlElementVisibility();

    this._$containerEl.append([this._$controllerLeftEl, this._$controllerRightEl]);

    const click = this._nameSpacedEvent('click');
    this._$controllerLeftEl.on(click, $.proxy(this.scrollBackward, this));
    this._$controllerRightEl.on(click, $.proxy(this.scrollForward, this));
};

/**
 * Initializes children passed in to be used with the scroller and kicks everything off
 *
 * @param {Array.<Element>} children The children to initialize
 * @private
 */
Scroller.prototype._initializeChildren = function (children) {
    this._childData = {};

    if (hasIntersectionObserver()) {
        const intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                const { target } = entry;
                const index = this._children.indexOf(target);

                this._childData[index] = entry.boundingClientRect;
                observer.unobserve(target);
            });
            this._resizeAction(); // kick everything off
        });

        for (let i = 0; i < children.length; i++) {
            intersectionObserver.observe(children[i]);
        }
    }
};

/**
 * @param {string} eventName The name of the event to namespace
 * @returns {string} The namespaced event
 * @private
 */
Scroller.prototype._nameSpacedEvent = function (eventName) {
    return [eventName, this._eventNameSpace].join('.');
};

/**
 * Recalculates all of the necessary dimensions for the given screen size and re-positions everything
 *
 * @private
 */
Scroller.prototype._resizeAction = function () {
    if (this._resizeHandle) {
        window.clearTimeout(this._resizeHandle);
    }

    this._resizeHandle = window.setTimeout(
        $.proxy(function () {
            this._syncChildData();

            // Scroll the current child to the left of the viewport in case it was "pushed" by a resize
            this._scrollContainer(this._getChildScrollLeftByIndex(this._currentIndex), true);
        }, this),
    );
};

/**
 * Scrolls the container by a given amount (in pixels)
 *
 * @param {number} scrollAmountInPixels the amount to scroll the container element (in pixels)
 * @param {boolean=} opt_shouldForce whether to force the animation to run
 * @private
 */
Scroller.prototype._scrollContainer = function (scrollAmountInPixels, opt_shouldForce) {
    if (!this._isScrolling || opt_shouldForce) {
        this._isScrolling = true;

        this._$containerBodyEl.stop().animate(
            {
                scrollLeft: scrollAmountInPixels,
            },
            $.proxy(function () {
                this._isScrolling = false;
                this._updateControlElementVisibility();
            }, this),
        );
    }
};

/**
 * Updates the visibility of the left and right navigation elements.
 * @private
 */
Scroller.prototype._updateControlElementVisibility = function () {
    this._$controllerLeftEl.toggleClass('hidden', this._currentIndex === 0);

    // Now check if the rightmost element is in view
    const lastIndex = this._children.length - 1;
    // Only proceed if childData has been loaded for the last child
    if (this._childData && this._childData[lastIndex]) {
        const bodyScrollLeft = this._$containerBodyEl.scrollLeft();
        const bodyWidth = this._$containerBodyEl.width();
        const lastChildScrollLeft = this._getChildScrollLeftByIndex(this._children.length - 1);
        const isLastChildInViewport = lastChildScrollLeft < bodyScrollLeft + bodyWidth;

        this._$controllerRightEl.toggleClass('hidden', isLastChildInViewport);
    }
};

/**
 * Returns an child Element by index
 * @param {number} index
 * @returns {Element|undefined} The child element, if any, at the given index
 * @private
 */
Scroller.prototype._getChildByIndex = function (index) {
    return this._children[index];
};

/**
 * Determines the scrollLeft value a child should have based on child index
 *
 * @param {number} index
 * @returns {number} The scrollLeft value a child should have based on its index
 * @private
 */
Scroller.prototype._getChildScrollLeftByIndex = function (index) {
    let scrollLeft = 0;
    for (let i = 0; i < index; i++) {
        scrollLeft += this._childData[i].width;
    }
    return scrollLeft;
};

/**
 * Updates the current index based on the scrollLeft value of the container
 * @private
 */
Scroller.prototype._updateCurrentIndexByContainerPosition = function () {
    const scrollLeft = this._$containerBodyEl.scrollLeft();
    let runningWidths = 0;

    for (let i = 0; i < this._children.length; i++) {
        if (runningWidths + this._childData[i].width <= scrollLeft) {
            runningWidths += this._childData[i].width;
        } else {
            this._currentIndex = i;
            break;
        }
    }
};

/**
 * Updates the current childData. Used after a screen resize.
 * @private
 */
Scroller.prototype._syncChildData = function () {
    for (let i = 0; i < this._children.length; i++) {
        this._childData[i] = this._children[i].getBoundingClientRect();
    }
};

export default Scroller;
